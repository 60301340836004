<template>
  <div class="base-form">
    <page-form
      ref="baseFormRef"
      :baseFormConfig="baseFormConfig"
      :pageTitle="pageTitle"
      :pageInfo="pageInfo"
      pageType="view"
    >
      <template #customForm>
        <el-col
          :span="24"
          style="padding: 18px"
          v-for="item in currentParameterData"
          :key="item.key"
        >
          <LTable
            :ref="(el) => setTableRef(item.key, el)"
            :listDataOptions="item.value"
            :templateType="templateType"
            :appName="item.key"
            :isShowCheckItem="false"
            :isViewPage="true"
          />
        </el-col>
      </template>
    </page-form>
  </div>
</template>

<script setup>
import pageForm from '@/components/PageForm/page-form.vue'
import { baseFormConfig } from './config/view.js'
import { useStore } from 'vuex'
import { ref, onMounted, watch } from 'vue'
import i18n from '@/i18n'
import { useRoute } from 'vue-router'
import { cloneDeep } from 'lodash'
import { getCurrentSystemTimeFormat } from '@/utils/util.js'
import LTable from './dynamic-table2.vue'
// 页面标题
const pageTitle = ref('')
const { t } = i18n.global
const route = useRoute()
const sn = route.params.sn
const pageInfo = ref({})
pageTitle.value = t('general.router-monitor-status-detail')

const tableRefs = ref({})

const setTableRef = (key, el) => {
  if (el) {
    tableRefs.value[`tableRef${key}`] = el
  }
}

const store = useStore()
const listData = ref([])
const getParameterList = async (type) => {
  listData.value = await store.dispatch('param/getAllParameters', type)
}

onMounted(async () => {
  await getParameterList('00')
})

const parameterList = ref(null)
const selectedItems = ref([])
watch(
  () => parameterList?.value?.selectedListData,
  (newVal) => {
    if (newVal) {
      selectedItems.value = newVal?.map((item) => item.key)
    }
  }
)

const currentParameterData = ref([])
// const batteryInfo = computed(() => {
//   return (battery) => {
//     if (battery) {
//       const all = battery.split('/')[1]
//       const current = battery.split('/')[0]
//       return (all / current).toFixed(2)
//     } else {
//       return '0'
//     }
//   }
// })

// const getMemoryInfo = (memory) => {
//   console.log(memory, 'memory')
//   if (memory) {
//     const all = memory.split('/')[1]
//     const current = memory.split('/')[0]
//     return ((current / all) * 100).toFixed(2)
//   } else {
//     return '0'
//   }
// }
const getCurrentPageInfo = async () => {
  // 获取当前页面的初始化值
  const data = await store.dispatch('monitor/getMonitorStatusDetailBySn', sn)
  const detailInfo = cloneDeep(data)
  console.log(detailInfo?.parameter, 'detailInfo')
  if (detailInfo?.parameter) {
    console.log('------------------------------------------')

    const tableData = Object.entries(JSON.parse(detailInfo?.parameter)).map(
      ([key, value]) => {
        return {
          key,
          value
        }
      }
    )
    for (let i = 0; i < tableData.length; i++) {
      tableData[i].value = Object.entries(tableData[i].value).map(
        ([key, value]) => {
          return {
            key,
            value
          }
        }
      )
    }
    console.log(tableData)
    currentParameterData.value = tableData
  }

  detailInfo.updatedTime = getCurrentSystemTimeFormat(detailInfo.updatedTime)
  pageInfo.value = detailInfo
}

getCurrentPageInfo()
</script>

<style lang="scss" scoped>
.status-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
}
.circle {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 5px;
}
.online-circle {
  background-color: green;
}
.offline-circle {
  background-color: grey;
}

.percentage-label {
  display: block;
  font-size: 12px;
  margin-top: 10px;
}
</style>
